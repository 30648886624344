import React from 'react';

function Foot() {
  return (
    <div className="h-96 bg-slate-800 w-full text-white">
      <div></div>
      
    </div>
  );
}
export default Foot;